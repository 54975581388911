import { Box } from '@mui/material';

import useSubProperty from './sub-property-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';

const SubProperties = () => {
  const { subProperties, loading, hotelServID } = useSubProperty();
  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: 'auto',
          background: '#F4F4F4',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {Array.isArray(subProperties) &&
          subProperties
            ?.filter(sp => sp)
            ?.map((sp: any) => {
              return (
                <ServiceCard
                  showInfo={false}
                  key={sp?.name}
                  label={sp?.name}
                  path={`../service-information/${hotelServID}?SPID=${sp?.id}`}
                  description={sp?.desc}
                  image={sp?.img as string}
                />
              );
            })}
      </Box>
    </Layout>
  );
};

export default SubProperties;
