import {
  Box,
  Theme,
  SxProps,
  Typography,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CardPaper } from './CardPaper';

interface SliderProp {
  title: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  onTitleClick?: () => void;
}

const Slider = ({ title, children, sx, loading, onTitleClick }: SliderProp) => {
  return (
    <Box sx={{ mt: 3, ...(sx ? sx : {}) }}>
      <Typography
        variant="h6"
        sx={{ mb: 1 }}
        onClick={() => onTitleClick && onTitleClick()}
      >
        {title}
      </Typography>

      {!loading ? (
        <Box
          sx={{
            mt: 1,
            overflowX: 'scroll',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          {children}
        </Box>
      ) : (
        <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

interface SliderCardProp {
  index: number;
  title?: any;
  link?: string;
  icon: React.ReactNode;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  gettitle?: string;
  externalLink?: string | null;
  onClick?: () => void;
}

const SliderCard = ({
  index,
  title,
  link,
  icon,
  sx,
  gettitle,
  externalLink,
  onClick
}: SliderCardProp) => {
  return (
    <Box
      sx={{
        mt: 0.5,
        display: 'inline-block',
        ml: index !== 0 ? 1.5 : 1,
        ...(sx ? sx : {})
      }}
    >
      {externalLink && (
        <a href={externalLink} target="_blank" rel="noopener noreferrer">
          <CardPaper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
              py: 2,
              width: theme => theme.spacing(10),
              height: theme => theme.spacing(10)
            }}
          >
            {typeof icon === 'string' ? (
              <img src={icon} width={'40px'} alt={gettitle}></img>
            ) : (
              icon
            )}
          </CardPaper>
        </a>
      )}

      {link && (
        <RouterLink to={link || ''}>
          <CardPaper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
              py: 2,
              width: theme => theme.spacing(10),
              height: theme => theme.spacing(10)
            }}
          >
            {typeof icon === 'string' ? (
              <img src={icon} width={'40px'} alt={gettitle}></img>
            ) : (
              icon
            )}
          </CardPaper>
        </RouterLink>
      )}

      {onClick && (
        <CardPaper
          sx={{
            px: 3,
            py: 2.5,
            width: theme => theme.spacing(10)
          }}
          onClick={onClick}
        >
          {typeof icon === 'string' ? (
            <img src={icon} width={'30px'} alt={gettitle}></img>
          ) : (
            icon
          )}
        </CardPaper>
      )}

      <Typography
        variant="body2"
        textAlign="center"
        sx={{ mt: 1.5, color: 'text.primary' }}
      >
        {title}
      </Typography>
      {gettitle && (
        <Tooltip enterTouchDelay={0} title={gettitle}>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ mt: 1.5, color: 'text.primary' }}
          >
            {gettitle.length > 9 ? gettitle?.slice(0, 9) + '...' : gettitle}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export { Slider, SliderCard };
