import * as React from 'react';
import { useAuth } from '../../store';
import { FullScreenLoader } from '../../components';
import { HeaderLayout, Layout } from '../InRoomDining';
import { useGetInvoiceQuery } from '../../generated/graphql';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import useInfo from '../Dashboard/components/InfoCard';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  // background: 'none',
  // boxShadow: 'none',
  // transition: 'none',
  // fontSize: '12px',
  // lineHeight: '3.5rem',
  // marginBottom: '5px'
  borderBottom: '1px dashed #8C8C8C'
}));

function Invoice() {
  const guest = useAuth((state: any) => state.guest);

  const { data: invoice, loading: loadingInvoice } = useGetInvoiceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      propertyID: guest?.property_id,
      guestID: guest?.guest_id,
      roomID: guest?.room
    },
    skip: !guest?.guest_id
  });

  const { currency, loading } = useInfo();

  if (loadingInvoice || loading) return <FullScreenLoader />;

  return (
    <Layout>
      <HeaderLayout title="Invoice" />
      <Box
        sx={{
          //   p: 2,
          flex: 1,
          overflow: 'auto',
          textAlign: 'left',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <TableContainer component={Paper} sx={{ p: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>
                  <Typography>Service/Product</Typography>
                </TableCellStyled>
                <TableCellStyled align="right">
                  <Typography>Amount</Typography>
                </TableCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice?.getInvoice?.bill_items?.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow sx={{ borderBottom: '1px dashed #8C8C8C' }}>
                    <TableCellStyled align="left">
                      <Typography fontSize={14}>{row?.item_date}</Typography>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Typography fontSize={14}>{row?.description}</Typography>
                    </TableCellStyled>
                    <TableCellStyled align="right">
                      <Typography fontSize={14}>
                        {currency} {row?.item_amount}
                      </Typography>
                    </TableCellStyled>
                  </TableRow>
                </React.Fragment>
              ))}
              <TableRow>
                <TableCellStyled>
                  <Typography fontSize={16}>Total</Typography>
                </TableCellStyled>
                <TableCellStyled align="right" colSpan={2}>
                  <Typography fontSize={16}>
                    {currency} {invoice?.getInvoice?.bill_balance}
                  </Typography>
                </TableCellStyled>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}

export default Invoice;
