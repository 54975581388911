import { Box, Chip, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../store';
import { ChatIcon } from '../../../components';
import { imageFallback } from '../../../utils';
import useHotelInfo from '../../HotelInfo/hotel-info-hooks';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Header = () => {
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const guestName = guest?.guest_name || '';
  const { propertyDetails } = useHotelInfo();

  return (
    <Box
      sx={{
        px: 2,
        display: 'flex',
        fontWeight: 600,
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: theme => theme.spacing(2.5)
      }}
    >
      <Box>
        <Box>
          {propertyDetails?.getPropertyByDomain?.palm_text}
          {propertyDetails?.getPropertyByDomain?.palm_icon && (
            <Box
              width={36}
              height={36}
              component="img"
              alt="Hello Emoji"
              onError={imageFallback}
              src="/assets/hand-emoji.png"
              style={{ verticalAlign: 'bottom' }}
            />
          )}
        </Box>
        <Box>{guestName}</Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {propertyDetails?.getPropertyByDomain?.whatsapp_icon && (
          <IconButton
            aria-label="Linkedin.com"
            onClick={() =>
              window.open(
                `https://wa.me/${propertyDetails?.getPropertyByDomain?.restaurant_phone}?text=Guest_Name:=${guest?.guest_name}%0ARoom_Number:=${guest?.room}`
              )
            }
          >
            <WhatsAppIcon fontSize="large" />
          </IconButton>
        )}
        {propertyDetails?.getPropertyByDomain?.show_talk_to_us && (
          <Chip
            variant="filled"
            color="primary"
            label="Chat With Us"
            deleteIcon={<ChatIcon />}
            onClick={() => navigate('/chat')}
            onDelete={() => navigate('/chat')}
            sx={{ fontSize: theme => theme.spacing(1.75) }}
          />
        )}
      </Stack>
    </Box>
  );
};

export { Header };
