import {
  styled,
  Paper as MiUiPaper,
  Box,
  SxProps,
  Theme,
  Typography,
  Stack,
  CircularProgress
} from '@mui/material';

import { CardPaper } from './CardPaper';
import { useAuth } from '../../../store';
import { useMyOrdersQuery } from '../../../generated/graphql';
import { Link } from 'react-router-dom';
import { useTaskHistoryContext } from '../../../context/TaskHistoryContext';

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.spacing(1.25),
  backgroundColor: theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary.main}`
}));

interface ServiceAndOrderProps {
  sx?: SxProps<Theme>;
}

function ServiceAndOrder({ sx }: ServiceAndOrderProps) {
  const guest = useAuth((state: any) => state.guest);
  const { taskHistoryData, loadingTaskHistory } = useTaskHistoryContext();

  const { data: orders, loading: loadingOrders } = useMyOrdersQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      guestID: guest?.guest_id,
      propertyID: guest?.property_id
    },
    skip: !guest?.guest_id
  });

  const orderHistory = orders?.getGuestOrders;
  const taskHistory = taskHistoryData?.getTaskHistory;

  if (loadingOrders || loadingTaskHistory) {
    return (
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!orderHistory?.length && !taskHistory?.length) return null;

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 1, ...(sx ? sx : {}) }}>
      {Boolean(orderHistory?.length) && (
        <Link to="order-history" style={{ textDecoration: 'none', flex: 1 }}>
          <CardPaper sx={{ mr: 0.5, px: 3, py: 2.5 }}>
            <Stack direction="row" justifyContent={'center'}>
              <Typography
                align="center"
                variant="body2"
                sx={{ fontWeight: 600, color: 'primary.main' }}
              >
                My Basket
              </Typography>
              <Typography
                align="center"
                variant="body2"
                ml={1}
                sx={{ fontWeight: 600, color: 'primary.main' }}
              >
                ({orderHistory?.length})
              </Typography>
            </Stack>
          </CardPaper>
        </Link>
      )}
      {Boolean(taskHistory?.length) && (
        <Link to="service-history" style={{ textDecoration: 'none', flex: 1 }}>
          <CardPaper sx={{ ml: 0.5, px: 3, py: 2.5, width: '100%' }}>
            <Stack direction="row" justifyContent={'center'}>
              <Typography
                align="center"
                variant="body2"
                sx={{ fontWeight: 600, color: 'primary.main' }}
              >
                Service Requests
              </Typography>
              <Typography
                align="center"
                variant="body2"
                ml={1}
                sx={{ fontWeight: 600, color: 'primary.main' }}
              >
                ({taskHistory?.length})
              </Typography>
            </Stack>
          </CardPaper>
        </Link>
      )}
    </Box>
  );
}

export { ServiceAndOrder };
