import { Box, SxProps, Theme, Typography, Stack } from '@mui/material';

import { CardPaper } from './CardPaper';
import { Link } from 'react-router-dom';

interface InvoiceProps {
  sx?: SxProps<Theme>;
}

function Invoice({ sx }: InvoiceProps) {
  //   const guest = useAuth((state: any) => state.guest);
  //   const propertyID = guest?.property_id;
  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 1, ...(sx ? sx : {}) }}>
      <Link to="invoice" style={{ textDecoration: 'none', flex: 1 }}>
        <CardPaper sx={{ mr: 0.5, px: 3, py: 2.5 }}>
          <Stack direction="row" justifyContent={'center'}>
            <Typography
              align="center"
              variant="body2"
              sx={{ fontWeight: 600, color: 'primary.main' }}
            >
              View Bill
            </Typography>
            {/* <Typography
              align="center"
              variant="body2"
              ml={1}
              sx={{ fontWeight: 600, color: 'primary.main' }}
            >
              ({Invoice?.getItinerary?.length || 0})
            </Typography> */}
          </Stack>
        </CardPaper>
      </Link>
    </Box>
  );
}

export { Invoice };
